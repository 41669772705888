import { Button } from '@features/shared/components/button'
import { useIntl } from '@domains/i18n'
import { AttributeOptionsById } from '@features/pg-funnel/hooks/use-funnel-config/types'
import useBreakpoints from '@utils/hooks/use-breakpoints'
import styles from './index.module.scss'
import { ButtonType } from '@features/shared/types/enums/ButtonType'
import { ButtonVariant } from '@features/shared/types/enums/ButtonVariant'

interface IComparisonTableFooterProps {
  onClickButton: (value: string) => void;
  options: AttributeOptionsById
}

const ComparisonTableFooter = ({ onClickButton, options }: IComparisonTableFooterProps) => {
  const { formatMessage } = useIntl()
  const leftKey = Object.keys(options)[0]
  const rightKey = Object.keys(options)[1]

  const { currentBreakpoint, breakpoints } = useBreakpoints()
  const isMobile = currentBreakpoint === breakpoints.xs.name

  return (
    <tfoot className={styles['table-footer']}>
      <tr>
        <td />
        <td>
          <Button
            variant={ButtonVariant.Inverted}
            onClick={() => onClickButton(leftKey)}
            type={ButtonType.Button}
            dataTestId="club-page_membership_modal_cta_1"
          >
            {isMobile
              ? formatMessage('club-only.comparison.button-label-mobile')
              : formatMessage('club-only.comparison.button-label', { subscription: options[leftKey].title })}
          </Button>
        </td>
        <td>
          <Button
            onClick={() => onClickButton(rightKey)}
            type={ButtonType.Button}
            dataTestId="club-page_membership_modal_cta_2"
          >
            {isMobile
              ? formatMessage('club-only.comparison.button-label-mobile')
              : formatMessage('club-only.comparison.button-label', { subscription: options[rightKey].title })}
          </Button>
        </td>
      </tr>
    </tfoot>
  )
}

export default ComparisonTableFooter
