import { useIntl } from '@domains/i18n'
import styles from './index.module.scss'

interface IMoreInfoButtonProps {
  handleClick: (event: React.MouseEvent) => void;
  label?: string
}

export const MoreInfoButton = ({ handleClick, label }: IMoreInfoButtonProps) => {
  const { formatMessage } = useIntl()

  return (
    <button
      type="button"
      onClick={handleClick}
      className={styles.moreInfo}
      data-testid="club-page_membership_compare-link"
    >
      {label ?? formatMessage('attribute-choice.what-the-difference')}
    </button>
  )
}
