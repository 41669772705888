import { Badge } from 'src/features/shared/components/badge';
import { Button } from '@features/shared/components/button';
import { ButtonVariant } from '@features/shared/types/enums/ButtonVariant';
import useBreakpoints from '@utils/hooks/use-breakpoints';
import classNames from 'classnames';
import Icons from 'features/shared/components/icons';
import { Image } from 'src/features/shared/components/image';
import {RichText} from 'src/features/shared/components/rich-text';
import {
  Body,
  FontColor,
  H3,
  Note,
} from 'src/features/shared/components/typography';
import styles from './Card.module.scss';
import { UniqueSellingPoint } from './unique-selling-point';
import { Usp } from 'src/features/pg-funnel/hooks/use-funnel-config/types';

interface ICardProps {
  buttonLabel?: string;
  buttonVariant?: ButtonVariant;
  link?: { href: string };
  description?: React.ReactNode;
  image?: { alt: string; src: string; srcSet: string };
  title?: string;
  subtitle?: string;
  isIcon?: boolean;
  isPlain?: boolean;
  distance?: string;
  distanceDescription?: string;
  hasRichText?: boolean;
  beforePrice?: string;
  price?: string;
  priceLabel?: string;
  priceDescription?: string;
  hideImage?: boolean;
  isButtonAlignedRight?: boolean;
  isSideBySide?: boolean;
  hasFullWithImage?: boolean;
  usps?: Usp[];
  badge?: string;
}

export function Card({
  buttonLabel,
  buttonVariant,
  link,
  description,
  image,
  title,
  subtitle,
  isIcon,
  isPlain,
  distance,
  distanceDescription,
  hasRichText,
  beforePrice,
  price,
  priceLabel,
  priceDescription,
  hideImage,
  isButtonAlignedRight,
  isSideBySide,
  hasFullWithImage,
  usps,
  badge,
}: ICardProps) {
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isMobile = currentBreakpoint === breakpoints.xs.name;

  return (
    <div
      className={classNames(styles.card, {
        [styles['is-plain']]: isPlain,
        [styles['side-by-side']]: isSideBySide,
        [styles['full-width-image']]: hasFullWithImage,
      })}
    >
      {!hideImage && image && (
        <div className={styles.header}>
          {image && <Image className={styles.image} image={image} />}
          {badge && !isMobile && (
            <Badge className={styles.badge} label={badge} />
          )}
        </div>
      )}
      <div
        className={classNames(styles.body, {
          [styles['has-icon']]: isIcon,
          [styles['has-price']]: price,
        })}
      >
        <div className={styles.top}>
          <div>
            <H3
              className={styles.breakTitle}
              color={hasFullWithImage && FontColor.Neutral06}
            >
              {title}
            </H3>
            {subtitle && <Body className={styles.subtitle}>{subtitle}</Body>}
          </div>
          {isIcon && <Icons.ArrowRight className={styles.icon} />}
        </div>
        {description && (
          <div className={styles.description}>
            {hasRichText ? (
              <RichText isLight={hasFullWithImage}>
                <Body
                  component="div"
                  color={
                    hasFullWithImage ? FontColor.Neutral06 : FontColor.Neutral02
                  }
                >
                  {description}
                </Body>
              </RichText>
            ) : (
              <Body
                color={
                  hasFullWithImage ? FontColor.Neutral06 : FontColor.Neutral02
                }
              >
                {description}
              </Body>
            )}
          </div>
        )}
        {distance && (
          <div className={styles.footer}>
            <Icons.MarkerFilled className={styles.marker} />
            <Note color={FontColor.Neutral02}>
              {distance} {distanceDescription}
            </Note>
          </div>
        )}
        {usps && (
          <div className={styles.container}>
            <ul className={styles.list}>
              {usps?.map((usp) => (
                <UniqueSellingPoint
                  key={usp.id}
                  icon={usp.icon}
                  description={usp.description}
                  isGreyedOut={usp.isGreyedOut}
                />
              ))}
            </ul>
          </div>
        )}
        <div className={styles.bottom}>
          {price && (
            <div className={styles.price}>
              {priceLabel && (
                <Note className={styles.priceLabel}>{priceLabel}</Note>
              )}
              {beforePrice && (
                <Note className={styles.beforePrice} hasLineThrough>
                  {beforePrice}
                </Note>
              )}
              <div className={styles.priceContainer}>
                <H3 className={styles.price}>{price}</H3>
                <Note className={styles.priceDescription}>
                  {priceDescription}
                </Note>
              </div>
            </div>
          )}
          {buttonLabel && (
            <div
              className={classNames(styles.button, {
                [styles['aligned-right']]: isButtonAlignedRight,
              })}
            >
              <Button
                variant={link ? ButtonVariant.Link : buttonVariant}
                href={link?.href}
              >
                {buttonLabel}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
