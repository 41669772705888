import classNames from 'classnames';
import { useRichTextRenderer } from 'features/shared/hooks/useRichTextRenderer';
import { EntryFields } from 'contentful';
import Icons from 'features/shared/components/icons';
import styles from './UniqueSellingPoint.module.scss';
import { Body, FontColor } from 'src/features/shared/components/typography';
import { isRichText } from 'src/features/shared/contentful/utils/isRichText';

interface IUniqueSellingPointProps {
  icon?: string;
  description: EntryFields.RichText | string;
  isGreyedOut?: boolean;
}

export const UniqueSellingPoint = ({
  icon,
  description,
  isGreyedOut,
}: IUniqueSellingPointProps) => {
  const { renderRichText } = useRichTextRenderer();

  function getIcon() {
    switch (icon) {
      case 'Arrow':
        return Icons.ArrowRight;
      case 'Plus':
        return Icons.Plus;
      case 'Cross':
        return Icons.Cross;
      case 'Checkmark':
      default:
        return Icons.Checkmark;
    }
  }

  const Icon = getIcon();

  if (!description) {
    return null;
  }

  return (
    <li className={classNames(styles.usp)}>
      {
        <Icon
          className={classNames(styles.icon, { [styles.grey]: isGreyedOut })}
        />
      }

      <Body
        component="div"
        color={isGreyedOut ? FontColor.Neutral03 : FontColor.Neutral01}
      >
        <>
          {isRichText(description) ? renderRichText(description) : description}
        </>
      </Body>
    </li>
  );
};
