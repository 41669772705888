import { Badge } from 'src/features/shared/components/badge';
import { useIntl } from '@domains/i18n';
import { H4 } from 'src/features/shared/components/typography';
import styles from './index.module.scss';

const ComparisonTableHeader = () => {
  const { formatMessage } = useIntl();

  return (
    <thead className={styles['table-head']}>
      <tr>
        <th />
        <th>
          <H4>{formatMessage('club-only.comparison.basic-contract-name')}</H4>
        </th>
        <th>
          <Badge
            className={styles.badge}
            isTertiary
            label={formatMessage('club-only.comparison.popular')}
          />
          <H4>{formatMessage('club-only.comparison.plus-contract-name')}</H4>
        </th>
      </tr>
    </thead>
  );
};

export default ComparisonTableHeader;
