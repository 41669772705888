import { useIntl } from '@domains/i18n';
import { AttributeOptionsById } from '@features/pg-funnel/hooks/use-funnel-config/types';
import classNames from 'classnames';
import { RefObject } from 'react';
import { Sheet } from 'src/features/shared/components/sheet';
import {
  Body,
  FontColor,
  H2,
  H3,
} from 'src/features/shared/components/typography';
import { IUseSheetReturn } from 'src/features/shared/hooks/use-sheet';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import Checkmark from './checkmark-icon';
import ComparisonTableFooter from './comparison-table-footer';
import ComparisonTableHeader from './comparison-table-header';
import { ComparisonTableRow } from './comparison-table-row/ComparisonTableRow';
import Cross from './cross-icon';
import styles from './index.module.scss';

interface IClubOnlyComparisonDialog {
  onClickButton?: (value: string) => void;
  options?: AttributeOptionsById;
  availableClubsAmount?: number | string;
  isAlternative?: true;
  contentRef?: RefObject<HTMLDivElement>;
  sheet: IUseSheetReturn;
}

export const ClubOnlyComparisonDialog = ({
  onClickButton,
  isAlternative,
  availableClubsAmount,
  options,
  sheet,
}: IClubOnlyComparisonDialog) => {
  const { formatMessage } = useIntl();

  const Heading = isAlternative ? H2 : H3;

  const defaultAvailableClubs = formatMessage(
    'club-only.comparison.number-of-clubs.value.default',
  );
  const clubsAmount = availableClubsAmount || defaultAvailableClubs;

  const hardcodedTableRowData = [
    {
      label: formatMessage('club-only.comparison.all-facilities.label'),
      plusValue: <Checkmark />,
      oneValue: <Checkmark />,
      tooltipDescription: formatMessage(
        'club-only.comparison.all-facilities.tooltip',
      ),
    },
    {
      label: formatMessage('club-only.comparison.group-lessons.label'),
      plusValue: <Checkmark />,
      oneValue: <Checkmark />,
      tooltipDescription: formatMessage(
        'club-only.comparison.group-lessons.tooltip',
      ),
    },
    {
      label: formatMessage('club-only.comparison.opening-times.label'),
      plusValue: <Checkmark />,
      oneValue: <Checkmark />,
      tooltipDescription: formatMessage(
        'club-only.comparison.opening-times.tooltip',
      ),
    },
    {
      label: formatMessage('club-only.comparison.number-of-clubs.label'),
      plusValue: formatMessage('club-only.comparison.number-of-clubs.value', {
        number: clubsAmount,
      }),
      oneValue: formatMessage('club-only.comparison.number-of-clubs.value', {
        number: 1,
      }),
      tooltipDescription: formatMessage(
        'club-only.comparison.number-of-clubs.tooltip',
      ),
    },
    {
      label: formatMessage('club-only.comparison.sport-together.label'),
      plusValue: isAlternative ? (
        formatMessage('club-only.comparison.sport-together.plus-value', {
          number: 5,
        })
      ) : (
        <Checkmark />
      ),
      oneValue: <Cross />,
      tooltipDescription: formatMessage(
        'club-only.comparison.sport-together.tooltip',
      ),
    },
  ];

  const onCloseClick = () => {
    sheet.close();
    pushGA4Event(GA4_EVENTS.trackEvent, {
      event_name: 'close_more_info_membership',
    });
  };

  return (
    <Sheet sheet={sheet} position="middle" onCloseClick={onCloseClick}>
      <Heading className={classNames(!isAlternative && styles.title)}>
        {formatMessage('club-only.comparison.title')}
      </Heading>
      <Body
        color={FontColor.Neutral02}
        className={classNames(styles.body, {
          [styles.alternative]: isAlternative,
        })}
      >
        {formatMessage('club-only.comparison.description')}
      </Body>

      <table
        className={classNames(styles.table, {
          [styles.alternative]: isAlternative,
        })}
      >
        <ComparisonTableHeader />
        <tbody>
          {hardcodedTableRowData.map((row) => (
            <ComparisonTableRow
              key={row.label}
              tooltipTitle={row.label}
              values={[
                { key: `${row.label}-one`, value: row.oneValue },
                { key: `${row.label}-plus`, value: row.plusValue },
              ]}
              {...row}
            />
          ))}
        </tbody>
        {options && onClickButton && (
          <ComparisonTableFooter
            onClickButton={onClickButton}
            options={options}
          />
        )}
      </table>
    </Sheet>
  );
};
