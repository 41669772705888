import { Image } from '@features/shared/components/image';
import { Col, Row } from '@features/shared/components/layout';
import useBreakpoints from '@utils/hooks/use-breakpoints';
import { ClubPageSectionHeading } from 'src/features/shared/components/typography';
import styles from '../index.module.scss';

interface IClubPageHeaderProps {
  title: string;
  showIcon?: boolean;
}

export const ClubPageHeader = ({
  title,
  showIcon = true,
}: IClubPageHeaderProps) => {
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isMobile = currentBreakpoint === breakpoints.xs.name;
  const imageSrc = isMobile
    ? '/static/images/sportcity/banners/club/membership-title-mobile.svg'
    : '/static/images/sportcity/banners/club/membership-title-web.svg';

  return (
    <Row>
      <Col
        columns="xs:8 md:6"
        offset="xs:2 md:3"
        className={styles.clubTitleContainer}
      >
        <ClubPageSectionHeading>{title}</ClubPageSectionHeading>
      </Col>
      {showIcon && (
        <Col
          columns="xs:3 md:2"
          offset="xs:5 md:1"
          className={styles.clubTitleContainer}
        >
          <Image
            image={{
              src: imageSrc,
              srcSet: imageSrc,
              alt: 'membership-icon',
            }}
            className={styles.image}
          />
        </Col>
      )}
    </Row>
  );
};
