import { Tooltip } from '../../../../shared/components/tooltip';
import styles from './index.module.scss';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';

interface ITableRowValue {
  key: string;
  value: string | React.ReactNode;
}

interface IComparisonTableRow {
  label: string;
  values: ITableRowValue[];
  tooltipTitle: string;
  tooltipDescription: string;
}

export const ComparisonTableRow = ({
  label,
  values,
  tooltipTitle,
  tooltipDescription,
}: IComparisonTableRow) => (
  <>
    <tr className={styles['table-row']}>
      <td>
        <div className={styles['table-row-title']}>
          {/** some translations include a soft hyphen HTML char (&shy;) so we can control where the line break is */}
          <span dangerouslySetInnerHTML={{ __html: label }} />
          <Tooltip
            title={tooltipTitle}
            description={tooltipDescription}
            onOpenChange={(open) => {
              if (open) {
                pushGA4Event(GA4_EVENTS.trackEvent, {
                  event_name: 'click_info_subscription',
                });
              }
            }}
          />
        </div>
      </td>
      {values.map(({ key, value }) => (
        <td key={key}>{value}</td>
      ))}
    </tr>
  </>
);
