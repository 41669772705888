import classNames from 'classnames';
import theme from 'config/theme';
import { Tooltip } from 'src/features/shared/components/tooltip';
import { ITooltipProps } from 'src/features/shared/components/tooltip/Tooltip';
import { Note } from 'src/features/shared/components/typography';
import styles from './index.module.scss';

export interface BadgeProps {
  label: string;
  tooltip?: ITooltipProps;
  isSecondary?: boolean;
  isTertiary?: boolean;
  isPointed?: boolean;
  className?: string;
}

export function Badge({
  label,
  tooltip,
  isSecondary,
  isTertiary,
  isPointed,
  className,
}: BadgeProps) {
  const isPremium = theme.name === 'sportcitypremium';

  const classes = classNames([
    className,
    styles.badge,
    {
      [styles.secondary]: isSecondary,
      [styles.tertiary]: isTertiary,
      [styles.pointed]: isPointed,
      [styles['text-white']]: isPremium,
    },
  ]);
  return (
    <span className={classes}>
      <Note>
        {tooltip?.title || tooltip?.description ? (
          <Tooltip {...tooltip}>{label}</Tooltip>
        ) : (
          <>{label}</>
        )}
      </Note>
    </span>
  );
}
